import React, { useState, useEffect } from "react";
import {
    Section,
    NestableNavigationContent,
    NestingItem,
    ButtonItem,
    CustomItem,
    SideNavigation,
    NavigationHeader,
    Header,
    NavigationContent,
} from "@atlaskit/side-navigation";
import styled from "styled-components";
import Spinner from "../ui/spinner";
import InlineMessage from "@atlaskit/inline-message";
import { Link, useParams, useLocation } from "react-router-dom";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import { useModules } from "../../providers/modules-provider";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import RemoveFilter from "../filters/remove-filter";
import ChevronLeftCircleIcon from "@atlaskit/icon/glyph/chevron-left-circle";
import ChevronRightCircleIcon from "@atlaskit/icon/glyph/chevron-right-circle";
import Document24Icon from "@atlaskit/icon-file-type/glyph/document/24";
import { useTranslation } from "react-i18next";
import toBoolean from "../../utils/to-boolean";
import JiraFailedBuildStatusIcon from "@atlaskit/icon/glyph/jira/failed-build-status";
import IconWidget from "../widgets/icon-widget";
import { isVisible } from "../documents/document";
import Tabs from "@atlaskit/tabs";
import Button from "@atlaskit/button";
import { useAuth } from "../../providers/auth-provider";

function useHash() {
    return useLocation().hash?.slice(1);
}

function Sidebar({ document, addPadding = true }) {
    const { isPending, isError, modules, reload } = useModules();
    const { module, category } = useParams();
    const [visible, setVisible] = useState(() => {
        const value = localStorage.getItem("menubar.visible");
        if (!value) return true;
        return value === "true";
    });

    const toggle = () => {
        localStorage.setItem("menubar.visible", !visible);
        setVisible(v => !v);
    };

    const setCurrentStack = () => {
        if (module && category) {
            return [`${module}/${category}`.toLowerCase()];
        }

        return [];
    };

    const [stack, setStack] = useState(() => setCurrentStack());

    useEffect(() => {
        setStack(setCurrentStack());
    }, [module, category]);

    useEffect(() => {
        if (!(module && category)) {
            setStack([]);
        }
    }, [module, category]);


    const currentModule = modules?.find(m => m.slug.toLowerCase() === module);
    const categories = currentModule?.children;

    const isComponent = document?.document_type?.config?.isComponent ?? false;

    if (isComponent) {
        return <ComponentMenu
            modules={modules}
            currentModule={currentModule}
            document={document}
            visible={visible}
            toggle={toggle}
            addPadding={addPadding}
            isPending={isPending}
        />;
    }

    return <Wrapper visible={visible}>
        <ToggleButton visible={visible} toggle={toggle} />
        <ToggleBorder />
        <InnerWrapper visible={visible} addPadding={addPadding}>
            {isPending && modules?.length == 0 && <Spinner />}
            <SideNavigation label="project" >
                {currentModule && <NavigationHeader>
                    <Header>
                        <HeaderInner>
                            <Link to={`/modules/${currentModule?.slug?.toLowerCase()}`}>
                                {currentModule?.name}
                            </Link>
                        </HeaderInner>
                    </Header>
                </NavigationHeader>}
                {isError && <ErrorMessageWrapper data-testid="alert">
                    <InlineMessage type="error" secondaryText="Coś poszło nie tak." />
                </ErrorMessageWrapper>}
                {currentModule && categories?.length > 1 && <NestableNavigationContent stack={stack} onChange={setStack}>
                    <Module module={currentModule} reload={reload} document={document} />
                </NestableNavigationContent>}
                {currentModule && categories?.length == 1 && <NavigationContent>
                    <Module module={currentModule} reload={reload} document={document} />
                </NavigationContent>}
            </SideNavigation>
        </InnerWrapper>
    </Wrapper>;
}

export default Sidebar;

function Module({ module, reload, document }) {
    const categories = module.children;

    if (categories?.length == 1) {
        const category = categories[0];
        const id = `${module.name}/${category.name}`.toLowerCase();

        return <>
            <Section>
                {category.children.map(space => (
                    <Space key={space.id} space={space} category={category} module={module} reload={reload} document={document} />
                ))}
            </Section>
        </>;
    }

    return <>
        <Section>
            {categories.map(category => <Category key={category.id} module={module} category={category} reload={reload} document={document} />)}
        </Section>
    </>;
}

function Category({ module, category, reload, document }) {
    if (category.type === "space") {
        return <ButtonItem>
            <FlexStart>
                {category?.icon && <IconWrapper>
                    <IconWidget name={category?.icon} size="20px" />
                </IconWrapper>}
                <FlexMain>{category.name}</FlexMain>
            </FlexStart>
        </ButtonItem>;
    }

    return <NestingItem onClick={() => {
        const spaces = category.children.filter(s => !s?.hiddenMenu);
        if (spaces.length != 1) return;
        const first = spaces[0];
        const uri = `/${module.slug}/${category.slug}/${first.slug}`.toLowerCase();
        window.location.href = uri;
    }} title={
        <FlexStart>
            {category?.icon && <IconWrapper>
                <IconWidget name={category?.icon} size="20px" />
            </IconWrapper>}
            <FlexMain>{category.name}</FlexMain>
        </FlexStart>
    } id={`${module.slug}/${category.slug}`.toLowerCase()}>
        <Section title={category.name}>
            {category.children.map(space => (
                <Space key={space.id} space={space} category={category} module={module} reload={reload} document={document} />
            ))}
        </Section>
    </NestingItem>;
}

function ComponentMenu({ document, visible, toggle, addPadding, isPending, modules, currentModule }) {
    const { space, module, category } = useParams();
    const uri = `/${module}/${category}/${space}`.toLowerCase();

    const baseDocuments = document?.links?.filter(l => l?.link_type_id === 5 || l?.link_type_id === "5")?.map(l => l?.linked_document) ?? [];

    return <Wrapper visible={visible}>
        <ToggleButton visible={visible} toggle={toggle} />
        <ToggleBorder />
        <InnerWrapper visible={visible} addPadding={addPadding}>
            {isPending && modules?.length == 0 && <Spinner />}

            <SideNavigation label="project">
                {currentModule && <NavigationHeader>
                    {/* <Header>
                        <HeaderInner>
                            <Link to={`/modules/${currentModule?.slug?.toLowerCase()}`}>
                                {currentModule?.name}
                            </Link>
                        </HeaderInner>
                    </Header> */}
                </NavigationHeader>}
                {baseDocuments?.map(bd => {
                    const bdSpace = bd?.document_type?.space;
                    const bdCategory = bdSpace?.category;
                    const bdModule = bdCategory?.module;
                    const dSpace = document?.document_type?.space;
                    const dCategory = dSpace?.category;
                    const dModule = dCategory?.module;

                    const uri = `/${bdModule?.slug}/${bdCategory?.slug}/${bdSpace?.slug}/${bd?.id}#${dModule?.alias}/${dCategory?.name}/${dSpace?.name}`;
                    const bdName = bd?.values?.find(v => v?.document_type_field?.name == "name")?.value;

                    return <CustomItem key={bd?.id} component={Link} to={uri} isSelected={false}>
                        <FlexStart>
                            <IconWrapper>
                                <IconWidget name="arrow_circle_left" size="20px" />
                            </IconWrapper>
                            <FlexMain>{bdName}</FlexMain>
                        </FlexStart>
                    </CustomItem>;
                })}
                <DocumentMenu document={document} uri={uri} />
            </SideNavigation>
        </InnerWrapper>
    </Wrapper>;

}

function Space({ module, category, space, reload, document }) {
    const { space: slug } = useParams();
    const query = useQuery();
    const queryFilterId = query.get("filter");
    const { id } = useParams();
    const [filterToRemove, setFilterToRemove] = useState(null);

    const uri = `/${module.slug}/${category.slug}/${space.slug}`.toLowerCase();
    let isSelected = space.slug == slug && !queryFilterId && !id;
    const isSingle = document?.document_type?.space?.config?.view === "single";

    if (isSingle) {
        isSelected = space.slug == slug && !queryFilterId;
    }

    if (space?.hiddenMenu === true || space?.hiddenMenu === "true") {
        return null;
    }

    return <>
        {filterToRemove && <RemoveFilter documentTypeId={space?.document_type?.id} filter={filterToRemove} onClose={() => setFilterToRemove(null)} onRemoved={() => {
            reload();
            setFilterToRemove(null);
        }} />}
        <CustomItem component={Link} to={uri} isSelected={isSelected}>
            <FlexStart>
                {space?.icon && <IconWrapper>
                    <IconWidget name={space?.icon} size="20px" />
                </IconWrapper>}
                <FlexMain>{space.name}</FlexMain>
            </FlexStart>
        </CustomItem>
        {document && space.slug == slug && !queryFilterId && <DocumentMenu document={document} uri={uri} />}
        {space?.document_type?.filters?.filter(f => !f?.root_document_type_id)?.map(f => (
            <SpaceWrapper key={f.id}>
                <SpaceWrapperLink>
                    <Link to={uri + "?filter=" + f.id} key={f.id} >
                        <CustomItem cssFn={styles => ({
                            ...styles,
                            fontSize: "13px",
                            opacity: "0.8",
                            paddingLeft: "20px"
                        })} iconBefore={<FilterIcon />}
                            component={Link}
                            to={uri + "?filter=" + f.id}
                            isSelected={space.slug == slug && queryFilterId == f.id}>{f.name}</CustomItem>
                    </Link>
                </SpaceWrapperLink>
                <SpaceWrapperDropdown>
                    <DropdownMenu position="bottom right" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem onClick={() => setFilterToRemove(f)}>
                                Usuń filtr
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                </SpaceWrapperDropdown>
            </SpaceWrapper>
        ))}
    </>;
}

function DocumentMenu({ document, uri }) {
    const { id } = useParams();
    const currentSection = useHash();
    const { t } = useTranslation();
    const { user } = useAuth();

    const nameField = document?.document_type?.fields?.find(i => i?.name == "name");
    const name = document?.document_type?.config?.mainColumn
        ? document?.values[document?.document_type?.fields?.find(i => i?.name == document?.document_type?.config?.mainColumn)?.id] ?? "-"
        : document?.values[document?.document_type?.fields?.find(i => i?.name == "name")?.id] ?? "-";

    const sections = document?.document_type?.fields
        ?.filter(i => i?.field?.type == "section")
        ?.sort((a, b) => a?.position - b?.position)
        ?.filter(f => {
            const documentVisible = (document?.visible_roles ?? {})?.[f?.id];
            const visible = f?.visible_roles ?? [];
            const roles = user?.roles?.map(r => r?.id) ?? [];

            if (documentVisible?.length > 0) {
                const contains = roles?.some(id => documentVisible?.includes(id));
                return contains;
            }

            if (visible?.length > 0) {
                const contains = roles?.some(id => visible?.includes(id));
                return contains;
            }

            return true;
        });
    const isSelected = !currentSection && id == document?.id;

    const fields = fieldsWithSectionId(document?.document_type?.fields);
    const sectionFields = fields
        ?.filter(i => i?.field?.type != "section")
        ?.filter(i => i?.sectionId == null);

    const isMissing = sectionFields?.some(f => {
        const value = document?.values[f?.id];
        const isRequired = (toBoolean(f.required) || toBoolean(f?.must_have)) && !toBoolean(f.read_only);
        return !value && isRequired;
    });
    const isSingle = document?.document_type?.space?.config?.view === "single";

    if (isSingle) return null;

    return <DocumentMenuWrapper>
        <CustomItem cssFn={styles => ({
            ...styles,
            fontSize: "13px",
            opacity: "0.8",
        })}
            component={Link}
            to={`${uri}/${document?.id}`}
            isSelected={isSelected}>
            <DocumentNameWrapper>
                <DocumentIcon>
                    <Document24Icon />
                </DocumentIcon>
                <DocumentName>
                    <DocumentNameMain>
                        <FlexStart>
                            <FlexMain>{name}</FlexMain>
                            {isMissing && <div style={{ color: "red" }}>
                                <JiraFailedBuildStatusIcon size="small" />
                            </div>}
                        </FlexStart>
                    </DocumentNameMain>
                    {document?.document_type?.config?.additionalColumn && <DocumentNameAdditional>
                        {document?.values[document?.document_type?.fields?.find(i => i?.name == document?.document_type?.config?.additionalColumn)?.id] ?? "-"}
                    </DocumentNameAdditional>}
                </DocumentName>
            </DocumentNameWrapper>
        </CustomItem>
        {sections?.map(section => <DocumentMenuSection key={section?.id} section={section} uri={uri} document={document} />)}
        {document?.linkable
            ?.filter(l => l?.config?.sidebar !== false)
            ?.filter(l => linkableVisible(l, document, user))
            ?.sort((a, b) => a?.position - b?.position)?.map((l) => {
                const parts = l?.name?.split("/") ?? [];
                const name = parts[parts?.length - 1];
                // const documentsCount = document?.links?.filter(i => i?.linked_document?.document_type_id == l?.id)?.length;
                const documentsCount = document?.mapped_links_count?.[l?.name];
                const showMissing = toBoolean(l?.config?.showMissing ?? false);

                return <CustomItem
                    key={l?.id}
                    cssFn={styles => ({
                        ...styles,
                        fontSize: "13px",
                        opacity: "0.8",
                    })}
                    component={Link}
                    to={`${uri}/${document?.id}#${l?.name}`}
                    isSelected={decodeURI(currentSection) == l?.name}>
                    <FlexStart>
                        {l?.config?.icon && <IconWrapper><IconWidget name={l?.config?.icon} size="16px" /></IconWrapper>}
                        <FlexMain>{!l?.config?.icon && <span>&#8226; </span>}{name}</FlexMain>
                        {showMissing && documentsCount == 0 && <div style={{ color: "red" }}>
                            <JiraFailedBuildStatusIcon size="small" />
                        </div>}
                    </FlexStart>
                </CustomItem>;
            })}
        {document?.visible_sections?.links && <CustomItem cssFn={styles => ({
            ...styles,
            fontSize: "13px",
            opacity: "0.8",
        })}
            component={Link}
            to={`${uri}/${document?.id}#links`}
            isSelected={currentSection == "links"}>
            <FlexStart>

                <IconWrapper>
                    <IconWidget name="link" size="16px" />
                </IconWrapper>
                <FlexMain>
                    {t("linked_heading")}
                </FlexMain>
            </FlexStart>
        </CustomItem>}
        {document?.visible_sections?.comments && <CustomItem cssFn={styles => ({
            ...styles,
            fontSize: "13px",
            opacity: "0.8",
        })}
            component={Link}
            to={`${uri}/${document?.id}#comments`}
            isSelected={currentSection == "comments"}>
            <FlexStart>

                <IconWrapper>
                    <IconWidget name="comment" size="16px" />
                </IconWrapper>
                <FlexMain>
                    {t("comments_and_history")}
                </FlexMain>
            </FlexStart>
        </CustomItem>}
    </DocumentMenuWrapper>;
}

export function DocumentMenuTabs({ document, uri, currentSection, setCurrentSection }) {
    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useAuth();

    const nameField = document?.document_type?.fields?.find(i => i?.name == "name");
    const name = document?.document_type?.config?.mainColumn
        ? document?.values[document?.document_type?.fields?.find(i => i?.name == document?.document_type?.config?.mainColumn)?.id] ?? "-"
        : document?.values[document?.document_type?.fields?.find(i => i?.name == "name")?.id] ?? "-";

    // todo: Additional Field

    const sections = document?.document_type?.fields?.filter(i => i?.field?.type == "section")?.sort((a, b) => a?.position - b?.position);
    const isSelected = !currentSection && id == document?.id;

    const fields = fieldsWithSectionId(document?.document_type?.fields);
    const sectionFields = fields
        ?.filter(i => i?.field?.type != "section")
        ?.filter(i => i?.sectionId == null);

    const isMissing = sectionFields?.some(f => {
        const value = document?.values[f?.id];
        const isRequired = (toBoolean(f.required) || toBoolean(f?.must_have)) && !toBoolean(f.read_only);
        return !value && isRequired;
    });

    const tabs = [
        {
            key: "",
            label: name,
            content: null,
            isMissing,
        },
        ...sections?.map(section => {
            const sectionFields = fields
                ?.filter(i => i?.field?.type != "section")
                ?.filter(i => i?.sectionId == section?.id);
            const isMissing = sectionFields?.some(f => {
                const value = document?.values[f?.id];
                const isRequired = (toBoolean(f.required) || toBoolean(f?.must_have)) && !toBoolean(f.read_only);
                return !value && isRequired;
            });

            return {
                key: section?.name,
                label: section?.label,
                content: null,
                isMissing,
            };
        }),
        ...document?.linkable
            ?.filter(l => l?.config?.sidebar !== false)
            ?.filter(l => linkableVisible(l, document, user))
            ?.sort((a, b) => a?.position - b?.position)?.map((l) => {
                const parts = l?.name?.split("/") ?? [];
                const name = parts[parts?.length - 1];

                // const documentsCount = document?.links?.filter(i => i?.linked_document?.document_type_id == l?.id)?.length;
                // const showMissing = toBoolean(l?.config?.showMissing ?? false);

                const documentsCount = document?.mapped_links_count?.[l?.name];
                const showMissing = toBoolean(l?.config?.showMissing ?? false);

                return {
                    key: l?.name,
                    label: name,
                    content: null,
                    isMissing: showMissing && documentsCount == 0,
                };

            }),
        document?.visible_sections?.links && {
            key: "links",
            label: t("linked_heading"),
            content: null,
        },
        document?.visible_sections?.comments && {
            key: "comments",
            label: t("comments_and_history"),
            content: null,
        },
    ];

    return <DocumentTabsMenuWrapper>
        {tabs.map(t => {
            return (<div key={t.key}>
                <Button isSelected={currentSection == t.key} onClick={() => {
                    setCurrentSection(t.key);
                }} iconAfter={<>

                    {t?.isMissing && <MissingWrapper>
                        <JiraFailedBuildStatusIcon />
                    </MissingWrapper>}
                </>}>
                    {t.label}

                </Button>
            </div>);
        })}
    </DocumentTabsMenuWrapper>;

    // return <div>
    //     <Tabs tabs={tabs} onSelect={(t, index) => {
    //         window.location.hash = t?.key;
    //     }} isSelectedTest={(selected, t, index) => {
    //         console.log("is selected test", selected, t, index, currentSection);
    //         if (currentSection == "" && index == 0) return true;
    //         if (currentSection == t?.key) return true;
    //         return false;
    //     }} />
    // </div>;


    // return <DocumentMenuWrapper>
    //     <CustomItem cssFn={styles => ({
    //         ...styles,
    //         fontSize: "13px",
    //         opacity: "0.8",
    //     })}
    //         component={Link}
    //         to={`${uri}/${document?.id}`}
    //         isSelected={isSelected}>
    //         <DocumentNameWrapper>
    //             <DocumentIcon>
    //                 <Document24Icon />
    //             </DocumentIcon>
    //             <DocumentName>
    //                 <DocumentNameMain>
    //                     <FlexStart>
    //                         <FlexMain>{name}</FlexMain>
    //                         {isMissing && <div style={{ color: "red" }}>
    //                             <JiraFailedBuildStatusIcon size="small" />
    //                         </div>}
    //                     </FlexStart>
    //                 </DocumentNameMain>
    //                 {document?.document_type?.config?.additionalColumn && <DocumentNameAdditional>
    //                     {document?.values[document?.document_type?.fields?.find(i => i?.name == document?.document_type?.config?.additionalColumn)?.id] ?? "-"}
    //                 </DocumentNameAdditional>}
    //             </DocumentName>
    //         </DocumentNameWrapper>
    //     </CustomItem>
    //     {sections?.map(section => <DocumentMenuSection key={section?.id} section={section} uri={uri} document={document} />)}
    //     {document?.linkable
    //         ?.filter(l => l?.config?.sidebar !== false)
    //         ?.filter(l => linkableVisible(l, document))
    //         ?.sort((a, b) => a?.position - b?.position)?.map((l) => {
    //             const parts = l?.name?.split("/") ?? [];
    //             const name = parts[parts?.length - 1];
    //             const documentsCount = document?.links?.filter(i => i?.linked_document?.document_type_id == l?.id)?.length;
    //             const showMissing = toBoolean(l?.config?.showMissing ?? false);

    //             return <CustomItem
    //                 key={l?.id}
    //                 cssFn={styles => ({
    //                     ...styles,
    //                     fontSize: "13px",
    //                     opacity: "0.8",
    //                 })}
    //                 component={Link}
    //                 to={`${uri}/${document?.id}#${l?.name}`}
    //                 isSelected={decodeURI(currentSection) == l?.name}>
    //                 <FlexStart>
    //                     {l?.config?.icon && <IconWrapper><IconWidget name={l?.config?.icon} size="16px" /></IconWrapper>}
    //                     <FlexMain>{!l?.config?.icon && <span>&#8226; </span>}{name}</FlexMain>
    //                     {showMissing && documentsCount == 0 && <div style={{ color: "red" }}>
    //                         <JiraFailedBuildStatusIcon size="small" />
    //                     </div>}
    //                 </FlexStart>
    //             </CustomItem>;
    //         })}
    //     {document?.visible_sections?.links && <CustomItem cssFn={styles => ({
    //         ...styles,
    //         fontSize: "13px",
    //         opacity: "0.8",
    //     })}
    //         component={Link}
    //         to={`${uri}/${document?.id}#links`}
    //         isSelected={currentSection == "links"}>
    //         <FlexStart>

    //             <IconWrapper>
    //                 <IconWidget name="link" size="16px" />
    //             </IconWrapper>
    //             <FlexMain>
    //                 {t("linked_heading")}
    //             </FlexMain>
    //         </FlexStart>
    //     </CustomItem>}
    //     {document?.visible_sections?.comments && <CustomItem cssFn={styles => ({
    //         ...styles,
    //         fontSize: "13px",
    //         opacity: "0.8",
    //     })}
    //         component={Link}
    //         to={`${uri}/${document?.id}#comments`}
    //         isSelected={currentSection == "comments"}>
    //         <FlexStart>

    //             <IconWrapper>
    //                 <IconWidget name="comment" size="16px" />
    //             </IconWrapper>
    //             <FlexMain>
    //                 {t("comments_and_history")}
    //             </FlexMain>
    //         </FlexStart>
    //     </CustomItem>}
    // </DocumentMenuWrapper>;
}


const DocumentMenuWrapper = styled.div`
    max-width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
`;

const DocumentNameWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0px;
`;

const DocumentIcon = styled.div`
    padding-right: 10px;
`;

const DocumentNameMain = styled.div`
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    color: #172B4D !important;
`;

const DocumentNameAdditional = styled.div`

font-size: 12px;
font-weight: bold;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 170px;
color: #172B4D !important;
opacity: 0.7;
`;

const DocumentName = styled.div`
    flex: 1;
`;

function DocumentMenuSection({ document, section, uri }) {
    const currentSection = useHash();
    const isSelected = decodeURI(currentSection) == section?.name;
    const { user } = useAuth();

    const fields = fieldsWithSectionId(document?.document_type?.fields);
    const sectionFields = fields
        ?.filter(i => i?.field?.type != "section")
        ?.filter(i => i?.sectionId == section?.id);

    const isMissing = sectionFields?.some(f => {
        const value = document?.values[f?.id];
        const isRequired = (toBoolean(f.required) || toBoolean(f?.must_have)) && !toBoolean(f.read_only);
        return !value && isRequired;
    });

    const isAllHidden = sectionFields?.every(f => !isVisible(f, document, user));

    if (isAllHidden) return null;

    let options;

    try {
        options = JSON.parse(section?.options[0]);
        if (options?.hidden) return null;
    } catch (e) {
        return null;
    }

    return <SectionWrapper>
        <CustomItem cssFn={styles => ({
            ...styles,
            fontSize: "13px",
            opacity: "0.8",
        })}
            component={Link}
            to={`${uri}/${document?.id}#${section?.name}`}
            isSelected={isSelected}>
            <FlexStart>
                {options?.icon && <IconWrapper><IconWidget name={options.icon} size="16px" /></IconWrapper>}
                <FlexMain>{!options && <span>&#8226; </span>}{section?.label}</FlexMain>
                {isMissing && <div style={{ color: "red" }}>
                    <JiraFailedBuildStatusIcon size="small" />
                </div>}
            </FlexStart>
        </CustomItem>
    </SectionWrapper>;
}

const SectionWrapper = styled.div`

`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ToggleButton({ visible, toggle }) {
    return <ToggleButtonWrapper onClick={toggle}>
        {visible
            ? <ChevronLeftCircleIcon size={32} primaryColor="white" secondaryColor="black" />
            : <ChevronRightCircleIcon size={32} primaryColor="white" secondaryColor="black" />}
    </ToggleButtonWrapper>;
}

const ToggleButtonWrapper = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    right: -14px;
    top: 90px;
    z-index: 202;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 16px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToggleBorder = styled.div`
    width: 1px;
    height: 100%;
    background: #eee;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 190;
`;

const Wrapper = styled.div`
    width: ${p => p.visible ? "250px" : "15px"};
    min-height: 100%;
    position: relative;
`;

const InnerWrapper = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh);
    height: 100%;
    padding: 0 10px;
    background: #FAFBFC;
    padding-top: ${props => props.addPadding ? " 60px" : "0px"};
`;

const ErrorMessageWrapper = styled.div`
    padding: 20px;
    color: black;
`;

const SpaceWrapper = styled.div`
    display: flex;
    align-items: start;

`;

const SpaceWrapperLink = styled.div`
    flex-grow: 1;
    overflow: hidden;
`;

const SpaceWrapperDropdown = styled.div`
    padding-left: 10px;
`;

const HeaderInner = styled.div`
    a {
        color: rgb(66, 82, 110);
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;


const FlexStart = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
`;


const FlexMain = styled.div`
    flex: 1;
    white-space: normal;
    padding-top: 1px;
`;

function fieldsWithSectionId(fields) {
    const fieldsEnhanced = [];
    let currentSection = null;

    fields
        ?.sort((a, b) => a.position - b.position)
        ?.forEach(field => {
            if (field?.field?.type === "section") {
                currentSection = field?.id;
                field.sectionId = field?.id;
            } else {
                field.sectionId = currentSection;
            }

            fieldsEnhanced.push(field);
        });

    return fieldsEnhanced;
}

const IconWrapper = styled.div`
    margin-right: 5px;
`;

function linkableVisible(linkable, document, user) {
    const options = linkable?.config;

    try {

        const roles = user?.roles?.map(r => r?.id) ?? [];
        const visible = options?.visible_roles ?? [];

        if (visible?.length > 0) {
            const contains = roles?.some(id => visible?.includes(id));
            return contains;
        }

        if (options?.visibleWhen) {
            return options?.visibleWhen?.some((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;

                switch (operator) {
                    case "=":

                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }

        if (options?.visibleWhenAll) {
            return options?.visibleWhen?.every((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;

                switch (operator) {
                    case "=":

                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }
    } catch (e) {
        return true;
    }

    return true;
}

const DocumentTabsMenuWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const MissingWrapper = styled.div`
    width: 16px;
    margin-left: 5px;
    padding-top: 2px;
    color: red;
`;